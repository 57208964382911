<template>
  <member-setting-container>
    <b-card>
      <div class="d-flex justify-content-between flex-wrap my-1">
        <h4>{{ $t('backoffice.roles.my-roles') }}</h4>
      </div>
      <div v-if="!isLoading && selectedRoles && selectedRoles.length > 0">
        <b-table
          class="position-relative"
          :items="selectedRoles"
          responsive
          :small="isSmall"
          :fields="fields"
          primary-key="id"
          empty-text="loading"
        >
          <!-- Column: Role -->
          <template #cell(fullname)="{ item }">
            <b-media class="d-flex align-items-center">
              <b-link :to="getRoleLocation(item)" class="text-dark d-flex">
                <div class="ml-1">
                  <p class="font-weight-bold mb-0 text-dark">
                    {{ item.name }}
                  </p>
                </div>
              </b-link>
            </b-media>
          </template>

          <!-- Column: Tagname -->
          <template #cell(tagName)="{ item }">
            <div class="text-nowrap">
              <p class="mb-0 ml-1 pl-25">
                {{ item.tagName }}
              </p>
            </div>
          </template>

          <!-- Column: Namespace -->
          <template #cell(namespace)="{ item }">
            <div class="text-nowrap">
              <p class="mb-0 ml-1 pl-25">
                {{
                  item.namespace === 'backoffice' ? $t('backoffice.roles.backoffice') : $t('backoffice.roles.community')
                }}
              </p>
            </div>
          </template>

          <!-- Column: isGlobal -->
          <template #cell(isGlobal)="{ item }">
            <div class="text-nowrap">
              <p class="mb-0 ml-1 pl-25">
                <b-badge :variant="item.isGlobal ? 'light-primary' : 'light-info'">
                  {{ item.isGlobal ? $t('backoffice.roles.global-role') : $t('backoffice.roles.custom') }}
                </b-badge>
              </p>
            </div>
          </template>
        </b-table>
        <div class="d-flex justify-content-center">
          <b-pagination
            v-model="page"
            :total-rows="total"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
      <b-spinner v-else-if="isLoading" type="grow" small class=" text-primary d-block m-auto" />

      <b-row v-else class="horizontal-placeholder flex-column">
        <b-col cols="12">
          <img :src="placeholder" class="m-0 d-block mx-auto">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t('backoffice.feedback.placeholder-text', { item: $t('backoffice.roles.subtitle') }) }}
          </p>
        </b-col>
      </b-row>
      <b-modal
        id="new-role"
        :title="$t('backoffice.roles.new-role')"
        :ok-title="$t('form.actions.save')"
        ok-only
        @hidden="isValid = null"
        @ok="saveRols()"
      >
        <b-form-group class="flex-grow-1">
          <label for="rol-name" class="font-weight-bold"> {{ $t('backoffice.roles.name') }}*</label>
          <b-form-input
            id="rol-name"
            v-model="currentRole.name"
            type="text"
            :placeholder="$t('form.type-placeholder')"
            :state="isValid"
            @input="validator()"
          />
          <small v-if="isValid === false" class="text-danger mt-25">
            {{ $t('backoffice.roles.validator-message') }}
          </small>
        </b-form-group>
      </b-modal>
    </b-card>
  </member-setting-container>
</template>

<script>
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import MemberSettingContainer from '@/views/myself/components/MemberSettingContainer.vue';
import Placeholder from '@/assets/images/placeholders/light/general-placeholder.svg';

export default {
  name: 'Roles',
  components: {
    MemberSettingContainer,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      currentRole: {
        name: null,
      },
      isValid: null,
      isSmall: false,
      page: 1,
      perPage: 15,
      selectedFilter: false,
      selectedOption: null,
      selectedRoles: [],
      isLoading: false,
    };
  },
  computed: {
    options() {
      return [
        this.$t('backoffice.roles.all'),
        this.$t('backoffice.roles.community'),
        this.$t('backoffice.roles.backoffice'),
      ];
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    isDisabled() {
      return !this.form.name || !this.form.slug || !this.form.visibility || !this.form.accessibility;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    roles() {
      return this.$store.getters.memberRoles?.unpaginated[0]?.roles;
    },
    total() {
      return this.$store.getters.roles?.meta?.total;
    },
    currentMember() {
      return this.$store.getters.loggedMember;
    },
    placeholder() {
      return Placeholder;
    },
    fields() {
      return [
        {
          key: 'fullname',
          label: this.$t('backoffice.roles.name'),
        },
        {
          key: 'tagName',
          label: this.$t('backoffice.roles.label'),
        },
        {
          key: 'namespace',
          label: 'Namespace',
        },
        {
          key: 'isGlobal',
          label: this.$t('backoffice.analytics.data-type'),
        },
      ];
    },
  },
  watch: {
    page(page, perPage) {
      this.getRoles(page, perPage);
    },
    roles() {
      this.selectedRoles = this.roles;
    },
  },
  async created() {
    await this.fetchUser();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.selectedOption = this.options[0];
    this.selectedRoles = this.roles;
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    async getRoles(page, perPage) {
      await this.$store.dispatch('getRoles', {
        communitySlug: this.$store.getters.currentCollective.slug,
        page,
        perPage,
      });
    },
    getMemberRoles() {
      return this.$store.dispatch('getRoles', { memberKey: this.currentMember.key });
    },
    async saveRols() {
      try {
        if (this.currentRole.name === null || this.currentRole.name === '' || this.isValid === false) {
          this.notifyError(this.$t('backoffice.roles.messages.error'));
        } else {
          await this.$store.dispatch('createItem', {
            item: {
              itemType: 'createRoles',
              customName: 'roles',
              requestConfig: {
                roles: [this.currentRole],
              },
            },
          });
          this.notifySuccess(this.$t('backoffice.roles.messages.success'));
        }
      } catch {
        this.notifyError(this.$t('backoffice.roles.messages.error'));
      }
      this.currentRole = {
        name: null,
      };
    },
    handleResize() {
      this.isSmall = window.innerWidth < 400;
    },
    validator() {
      this.isValid = this.roles.every((role) => role.name !== this.currentRole.name);
    },
    getRoleLocation(role) {
      return {
        name: 'my-role-details',
        params: { role: role.name, id: role.key, communityId: this.$route.params.communityId, page: this.page },
      };
    },
    toggleFilters() {
      this.selectedFilter = !this.selectedFilter;
    },
    setSelectedRoles(selectedOption) {
      this.selectedRoles = this.roles;
      const options = {
        [this.$t('backoffice.roles.all')]: 'all',
        [this.$t('backoffice.roles.community')]: 'community',
        [this.$t('backoffice.roles.backoffice')]: 'backoffice',
      };

      this.selectedRoles = this.selectedRoles.filter(item => options[selectedOption] === 'all' ? item : item?.namespace === options[selectedOption]);
    },
    async fetchUser() {
      console.log('MyRoles fetchUser to get communityMembers');
      this.isLoading = true;
      await this.$store.dispatch('getItems', {
        itemType: 'communityMembers',
        customName: 'memberRoles',
        forceAPICall: true,
        requestConfig: {
          communitySlug: this.$store.getters.currentCollective.slug,
          username: this.currentMember.username,
          isForProfile: true,
          isForBackoffice: 1,
          getMinimalData: 1
        },
      });
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.model-type p:first-letter {
  text-transform: uppercase;
}
</style>
